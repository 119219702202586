import React from "react"

import Home from "./home"

const IndexPage = () => {

  return (
    <Home />
  )
}

export default IndexPage
